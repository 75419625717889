// This intentionally doesn't use the base query as it's unauthenticated
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: (() => {
    try {
      // Nasty way to avoid adding an extra ENV var for testRoutes base URL
      const apiBaseUrl = new URL(process.env.NEXT_PUBLIC_REDESIGN_API_BASE_URL ?? '', 'https://createiq.invalid')
      apiBaseUrl.pathname = '/'
      return apiBaseUrl.origin === 'https://createiq.invalid' ? apiBaseUrl.pathname : apiBaseUrl.toString()
    } catch {
      return ''
    }
  })(),
  credentials: 'include',
})

const swaggerApi = createApi({
  reducerPath: 'swaggerApi',
  baseQuery,
  endpoints: build => ({
    getApiSpecification: build.query<object, void>({
      query: () => '/assets/swagger.json',
    }),
  }),
  tagTypes: [],
})

export const { useGetApiSpecificationQuery } = swaggerApi

export default swaggerApi
