// This intentionally doesn't use the base query
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { UpsertUserRequest, UpsertUserResponse } from 'types/User'

const baseQuery = fetchBaseQuery({
  baseUrl: (() => {
    try {
      // Nasty way to avoid adding an extra ENV var for testRoutes base URL
      const apiBaseUrl = new URL(process.env.NEXT_PUBLIC_REDESIGN_API_BASE_URL ?? '', 'https://createiq.invalid')
      apiBaseUrl.pathname = '/testRoutes'
      return apiBaseUrl.origin === 'https://createiq.invalid' ? apiBaseUrl.pathname : apiBaseUrl.toString()
    } catch {
      return ''
    }
  })(),
  credentials: 'include', // Include cookies with all requests so that httpOnly cookies (BE SessionId) are transmitted for cross-authentication
})

const testRoutesApi = createApi({
  reducerPath: 'testRoutesApi',
  baseQuery,
  endpoints: build => ({
    upsertUser: build.mutation<UpsertUserResponse, UpsertUserRequest>({
      query: body => ({
        url: '/userWithRoleAccount',
        method: 'POST',
        body,
      }),
    }),
  }),
  tagTypes: [],
})

export const { useUpsertUserMutation } = testRoutesApi

export default testRoutesApi
