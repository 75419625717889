import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContextState } from 'types/UserContext'

export const initialState: ContextState = {
  userId: '',
  name: '',
  role: '',
  roleLabel: '',
  isSso: false,
  signedInByIntegration: false,
  profileColour: '#DC2773',
  account: {
    accountId: '',
    accountName: '',
    issues: {
      unclaimedEntities: 0,
      advisorRequests: [],
    },
    isOneLink: false,
    isAdvisor: false,
  },
  subAccounts: [],
  clientSubAccounts: [],
  userFlags: {
    displayIntroduction: false,
    displayIntroductionCounter: 0,
  },
  enabledFeatures: [],
  availableActions: {
    canManageAdvisorRelationships: false,
    canManageAdvisorUsers: false,
  },
  emailAddress: '',
}

const contextSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setContext: (state, { payload }: PayloadAction<ContextState>) => payload,
  },
})

export const { setContext } = contextSlice.actions
export default contextSlice.reducer
