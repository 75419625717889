import { getContrastRatio } from '@mui/material'
import { Theme } from '@mui/material/styles'

// Function is from createPalette in the MUI repository
// https://github.com/mui/material-ui/blob/master/packages/mui-material/src/styles/createPalette.js#L194-L217)
export default function getContrastText(theme: Theme, background: string, lightColor?: string, darkColor?: string) {
  const contrastText =
    getContrastRatio(background, theme.palette.common.white) >= theme.palette.contrastThreshold
      ? (lightColor ?? theme.palette.common.white)
      : (darkColor ?? theme.palette.common.black)

  if (process.env.NODE_ENV !== 'production') {
    const contrast = getContrastRatio(background, contrastText)
    if (contrast < 3) {
      console.error(
        [
          `MUI: The contrast ratio of ${contrast}:1 for ${contrastText} on ${background}`,
          'falls below the WCAG recommended absolute minimum contrast ratio of 3:1.',
          'https://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-contrast',
        ].join('\n')
      )
    }
  }

  return contrastText
}
