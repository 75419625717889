import {
  UserFormData,
  UserSwitcherAccount,
  UserSwitcherUser,
} from 'components/organisms/UserSwitcher/UserSwitcher.types'
import { ContextState } from 'types/UserContext'

export function buildEmail(
  {
    userFirstName: firstName,
    userLastName: lastName,
    roleType,
  }: Pick<UserSwitcherUser, 'userFirstName' | 'userLastName' | 'roleType'>,
  { side, domain }: Pick<UserSwitcherAccount, 'side' | 'domain'>
) {
  // Mailinator does not enjoy 'admin' in email addresses
  const roleTypeForEmail = roleType ? roleType.replace(/admin/g, 'adm') : ''
  return `${firstName.replace(/ /g, '-')}.${lastName.replace(/ /g, '-')}-${roleTypeForEmail}-${
    side === 'left' ? 1 : side === 'right' ? 2 : 3
  }@${domain}`
}

export function buildUser(
  user: Pick<UserFormData, 'userFirstName' | 'userLastName' | 'userPassword' | 'roleType'>,
  account: UserSwitcherAccount
): UserFormData {
  const { domain, name } = account

  return {
    ...user,
    userEmail: buildEmail(user, account),
    accountName: name,
    accountAllowedDomain: domain,
  }
}

export function currentUserEmailFromContext(context: ContextState | undefined): string | undefined {
  return context?.email
}
