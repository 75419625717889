import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DraftingNotesState } from 'types/redesignStore'

export const initialState: DraftingNotesState = {
  electionNotesBeingEdited: [],
  generalNotesBeingEdited: [],
}

const draftingNotesSlice = createSlice({
  name: 'draftingNotes',
  initialState,
  reducers: {
    resetNotesBeingEdited: () => ({ ...initialState }),
    addNoteBeingEdited: (
      state,
      { payload }: PayloadAction<{ electionId?: string; noteType: 'internal' | 'external' }>
    ) => {
      const { electionId, noteType } = payload

      if (electionId) {
        const noteId = `${noteType}-${electionId}`
        return { ...state, electionNotesBeingEdited: [...state.electionNotesBeingEdited, noteId] }
      }
      return { ...state, generalNotesBeingEdited: [...state.generalNotesBeingEdited, noteType] }
    },
    removeNoteBeingEdited: (
      state,
      { payload }: PayloadAction<{ electionId?: string; noteType: 'internal' | 'external' }>
    ) => {
      const { electionId, noteType } = payload

      if (electionId) {
        const noteId = `${noteType}-${electionId}`
        return {
          ...state,
          electionNotesBeingEdited: state.electionNotesBeingEdited.filter(
            noteBeingEdited => noteBeingEdited !== noteId
          ),
        }
      }
      return {
        ...state,
        generalNotesBeingEdited: state.generalNotesBeingEdited.filter(noteBeingEdited => noteBeingEdited !== noteType),
      }
    },
  },
})

export default draftingNotesSlice.reducer

// No-argument actions need to be re-exported because redux-toolkit is wrong
export function resetNotesBeingEdited() {
  return draftingNotesSlice.actions.resetNotesBeingEdited(undefined)
}

export const { addNoteBeingEdited, removeNoteBeingEdited } = draftingNotesSlice.actions
