// This intentionally doesn't use baseApi so that we can augment the base query to add an extra auth header.
import type {
  CreateHtmlVersionDto,
  CreateSchemaVersionDto,
  CreateSettingsVersionDto,
  DocumentDetailsDto,
  DocumentId,
  DocumentMetadataDto,
  DocumentName,
  GetHtmlVersionData,
  GetSchemaVersionData,
  GetSettingsVersionData,
  ListDocumentsResponseDto,
  UpdateDocumentNameDto,
  VersionId,
} from '@createiq/tpl'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { defaultPrintSettings } from 'components/templates/NakhodaAdmin/DocEditor/ExportSettings/DocEditorExportSettings.consts'
import { getJWTCookie } from 'redesignStore/api/utils/common'
import enhanceBaseQuery from 'redesignStore/api/utils/enhanceBaseQuery'
import serializeRequestParams from 'redesignStore/api/utils/serializeRequestParams'
import { DocumentSchema } from 'types/Document'
import { PrintSettings } from 'types/Tpl'

const baseQuery = fetchBaseQuery({
  baseUrl: (() => {
    try {
      // Nasty way to avoid adding an extra ENV var for template base URL
      const apiBaseUrl = new URL(process.env.NEXT_PUBLIC_REDESIGN_API_BASE_URL ?? '', 'https://createiq.invalid')
      apiBaseUrl.pathname = '/template'
      return apiBaseUrl.origin === 'https://createiq.invalid' ? apiBaseUrl.pathname : apiBaseUrl.toString()
    } catch {
      return ''
    }
  })(),
  credentials: 'include', // Include cookies with all requests so that httpOnly cookies (BE SessionId) are transmitted for cross-authentication
  prepareHeaders(headers) {
    const token = getJWTCookie()
    if (token) {
      headers.set('Authorization', `API 623e6ee8343a0f9e75289c370d34b49b, Bearer ${token}`)
    }
    return headers
  },
  paramsSerializer: serializeRequestParams,
})

const adminDocEditorApi = createApi({
  reducerPath: 'adminDocEditorApi',
  baseQuery: enhanceBaseQuery(baseQuery),
  endpoints: build => ({
    getAllMetadata: build.query<ListDocumentsResponseDto['results'], void>({
      query: () => '/documents',
      transformResponse: ({ results }: ListDocumentsResponseDto) => results,
      providesTags: result => [
        'DocumentMetadata',
        ...(result?.map(({ uid }) => ({ type: 'DocumentMetadata' as const, id: uid })) ?? []),
      ],
    }),
    getMetadata: build.query<DocumentDetailsDto, { id: DocumentId }>({
      query: ({ id }) => `/documents/${id}`,
      providesTags(_result, _error, { id }) {
        return [
          { type: 'DocumentMetadata', id },
          { type: 'DocumentDetails', id },
        ]
      },
    }),
    duplicate: build.mutation<DocumentMetadataDto, { id: DocumentId }>({
      query: ({ id }) => ({
        url: `/documents/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags() {
        return [{ type: 'DocumentMetadata' }, { type: 'DocumentDetails' }]
      },
    }),
    rename: build.mutation<DocumentMetadataDto, { id: DocumentId; name: DocumentName }>({
      query: ({ id, name }) => ({
        url: `/documents/${id}`,
        method: 'PATCH',
        body: { name } satisfies UpdateDocumentNameDto,
      }),
      invalidatesTags(_1, _2, { id }) {
        return [
          { type: 'DocumentMetadata', id },
          { type: 'DocumentDetails', id },
        ]
      },
    }),
    lock: build.mutation<DocumentMetadataDto, { id: DocumentId }>({
      query: ({ id }) => ({
        url: `/documents/${id}/lock`,
        method: 'POST',
      }),
      invalidatesTags(_1, _2, { id }) {
        return [
          { type: 'DocumentMetadata', id },
          { type: 'DocumentDetails', id },
        ]
      },
    }),
    unlock: build.mutation<DocumentMetadataDto, { id: DocumentId }>({
      query: ({ id }) => ({
        url: `/documents/${id}/unlock`,
        method: 'POST',
      }),
      invalidatesTags(_1, _2, { id }) {
        return [
          { type: 'DocumentMetadata', id },
          { type: 'DocumentDetails', id },
        ]
      },
    }),
    createMetadata: build.mutation<DocumentMetadataDto, File>({
      query: file => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `/documents`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['DocumentMetadata', 'DocumentDetails'],
    }),
    deleteMetadata: build.mutation<void, { id: DocumentId }>({
      query: ({ id }) => ({
        url: `/documents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags(_1, _2, { id }) {
        return [
          { type: 'DocumentMetadata', id },
          { type: 'DocumentDetails', id },
        ]
      },
    }),
    getHTML: build.query<string, { id: DocumentId; version?: VersionId }>({
      async queryFn({ id, version }, _1, _2, fetchWithBQ) {
        // Don't throw errors on non-2xx status codes, handled below
        const {
          data: html,
          error,
          ...rest
        } = await fetchWithBQ({
          url: `/documents/${id}/html`,
          params: { version } satisfies Omit<GetHtmlVersionData, 'documentId'>,
          responseHandler: 'text',
        })

        if (error) {
          if (typeof error.status === 'number' && error.status >= 300) {
            return { data: '<div>HTML Template does not exist</div>', ...rest }
          }

          return { error, ...rest }
        }

        return { data: (html as string) || '<div>HTML Template is blank</div>', ...rest }
      },
      providesTags(_result, _error, { id }) {
        return [{ type: 'DocumentHTML', id }]
      },
    }),
    updateHTML: build.mutation<DocumentMetadataDto, { id: DocumentId; html: string }>({
      query: ({ id, html }) => ({
        url: `/documents/${id}/html`,
        method: 'POST',
        body: { file: html } satisfies CreateHtmlVersionDto,
      }),
      invalidatesTags(_1, _2, { id }) {
        return [
          { type: 'DocumentHTML', id },
          { type: 'DocumentMetadata', id },
          { type: 'DocumentDetails', id },
        ]
      },
    }),
    getSettings: build.query<PrintSettings, { id: DocumentId; version?: VersionId }>({
      async queryFn({ id, version }, _1, _2, fetchWithBQ) {
        // Don't throw errors on non-2xx status codes, handled below
        const {
          data: settings,
          error,
          ...rest
        } = await fetchWithBQ({
          url: `/documents/${id}/settings`,
          params: { version } satisfies Omit<GetSettingsVersionData, 'documentId'>,
        })

        if (error) {
          if (typeof error.status === 'number' && error.status >= 300) {
            console.error(`Error fetching settings: ${error.status}`)
            return { data: defaultPrintSettings, ...rest }
          }

          return { error, ...rest }
        }

        return { data: settings as PrintSettings, ...rest }
      },
      providesTags(_result, _error, { id }) {
        return [{ type: 'DocumentSettings', id }]
      },
    }),
    updateSettings: build.mutation<DocumentMetadataDto, { id: DocumentId; settings: PrintSettings }>({
      query: ({ id, settings }) => ({
        url: `/documents/${id}/settings`,
        method: 'POST',
        body: { file: JSON.stringify(settings, undefined, 2) } satisfies CreateSettingsVersionDto,
      }),
      invalidatesTags(_1, _2, { id }) {
        return [
          { type: 'DocumentSettings', id },
          { type: 'DocumentMetadata', id },
          { type: 'DocumentDetails', id },
        ]
      },
    }),
    getSchema: build.query<DocumentSchema, { id: DocumentId; version?: VersionId }>({
      async queryFn({ id, version }, _1, _2, fetchWithBQ) {
        // Don't throw errors on non-2xx status codes, handled below
        const {
          data: schema,
          error,
          ...rest
        } = await fetchWithBQ({
          url: `/documents/${id}/schema`,
          params: { version } satisfies Omit<GetSchemaVersionData, 'documentId'>,
        })

        if (error) {
          if (typeof error.status === 'number' && error.status >= 300) {
            console.error(`Error fetching schema: ${error.status}`)
            return { data: { children: [] }, ...rest }
          }

          return { error, ...rest }
        }

        return { data: schema as DocumentSchema, ...rest }
      },
      providesTags(_result, _error, { id }) {
        return [{ type: 'DocumentSchema', id }]
      },
    }),
    updateSchema: build.mutation<DocumentMetadataDto, { id: DocumentId; schema: DocumentSchema }>({
      query: ({ id, schema }) => ({
        url: `/documents/${id}/schema`,
        method: 'POST',
        body: { file: JSON.stringify(schema, undefined, 2) } satisfies CreateSchemaVersionDto,
      }),
      invalidatesTags(_1, _2, { id }) {
        return [
          { type: 'DocumentSchema', id },
          { type: 'DocumentMetadata', id },
          { type: 'DocumentDetails', id },
        ]
      },
    }),
  }),
  keepUnusedDataFor: 0, // Clear any cached query data as soon as components using the query are unmounted
  tagTypes: ['DocumentMetadata', 'DocumentDetails', 'DocumentHTML', 'DocumentSettings', 'DocumentSchema'],
})

export const {
  useGetAllMetadataQuery,
  useGetMetadataQuery,
  useLazyGetMetadataQuery,
  useDuplicateMutation,
  useRenameMutation,
  useLockMutation,
  useUnlockMutation,
  useCreateMetadataMutation,
  useDeleteMetadataMutation,
  useGetHTMLQuery,
  useLazyGetHTMLQuery,
  useUpdateHTMLMutation,
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetSchemaQuery,
  useLazyGetSchemaQuery,
  useUpdateSchemaMutation,
} = adminDocEditorApi

export const useLazyGetSchemaQuerySubscription = adminDocEditorApi.endpoints.getSchema.useLazyQuerySubscription
export const useLazyGetSettingsQuerySubscription = adminDocEditorApi.endpoints.getSettings.useLazyQuerySubscription
export const useLazyGetHTMLQuerySubscription = adminDocEditorApi.endpoints.getHTML.useLazyQuerySubscription

export default adminDocEditorApi
