import { defaultSanitizeConfig } from 'components/atoms/SanitizedHtml/SanitizedHtml.consts'
import { SanitizeConfig } from 'components/atoms/SanitizedHtml/SanitizedHtml.types'
import { PrintSettings } from 'types/Tpl'

export const dummyParams = {
  readableId: 'NAK-016C625314EEBE1A95A0DC15738A4D43',
  id: '016c6253-14ee-be1a-95a0-dc15738a4d43',
  state: 'draft',
  version: '0.1',
  dates: {
    started: '2019-08-05T15:08:27.758Z',
    stateReached: '2019-08-05T15:08:27.758Z',
    lastModified: '2019-08-05T15:10:04.615Z',
    execution: '2019-08-05T15:10:04.615Z',
    invitationSent: '2019-08-05T15:10:04.615Z',
    invitationAccepted: '2019-08-05T15:10:04.615Z',
    lastTurnSwap: '2019-08-05T15:10:04.615Z',
    lastNotification: '2019-08-05T15:10:04.615Z',
    nextNotificationAfter: '2019-08-05T15:10:04.615Z',
  },
}

export const allPages = 'allPages'
export const mainPage = 'main'

export const defaultPrintSettings: PrintSettings = {
  main: {
    footerFirstPage: `<table><tr><td style='text-align:center; width:100%;'><span data-value='readableId'></span>      <span data-if='version'>v<span data-value='version'></span></span>     <span data-value='dates.lastModified' data-format='date|MMMM d, yyyy HH:mm'></span></td></tr></table>`,
    footer: `<table><tr><td style='width:33.3%'></td>    <td style='text-align: center; width:33.3%;'>-<span data-value='page'></span>-</td>    <td style='text-align: right; width:33.3%;'><strong>Footer text</strong></td>  </tr>                                              <tr><td colspan='3' style='width:100%;'><span data-value='readableId'></span>  <span data-if="state != ('executionAgreed' || 'executed')"><br />  <span data-if='version'>v<span data-value='version'></span></span>     <span data-value='dates.lastModified' data-format='date|MMMM d, yyyy HH:mm'></span></span></td></tr></table>`,
    footerEvenPage: ``,
    headerFirstPage: ``,
    header: ``,
    headerEvenPage: ``,
  },
  styles: {
    allFormats: '<style></style>',
    pdf: '<style></style>',
    docx: '<style></style>',
    allFormatsFooter: '<style>td { font-size:11px; }</style>',
    pdfFooter: `<style></style>`,
    docxFooter: `<style></style>`,
    allFormatsHeader: '<style>td { font-size:11px; }</style>',
    pdfHeader: `<style></style>`,
    docxHeader: `<style></style>`,
  },
}

export const previewSanitizeConfig: SanitizeConfig = {
  ...defaultSanitizeConfig,
  ADD_TAGS: ['style', 'img'],
  FORCE_BODY: true,
  ADD_ATTR: ['style', 'src', 'alt', 'width', 'height'],
  ALLOWED_URI_REGEXP: /^(?:(?:http|https|ftp|mailto|data):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
}
