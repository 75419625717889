import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ModalsPathMap, SetModalParams } from 'components/molecules/Modal/ModalWrapper.types'
import { ModalState } from 'types/redesignStore'

export const initialState: ModalState<keyof ModalsPathMap> = {
  open: false,
  eventTargetId: undefined,
  modalComponent: undefined,
  ignoreClickOutside: false,
}

const setModalComponentActionName = 'setModalComponent'
export function setModalComponent<TComponentName extends keyof ModalsPathMap>(
  payload: SetModalParams<TComponentName>
): PayloadAction<SetModalParams<TComponentName>> {
  return {
    type: setModalComponentActionName,
    payload,
  }
}
setModalComponent.type = setModalComponentActionName

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    resetModal: () => ({ ...initialState, open: false, eventTargetId: undefined }),
  },
  extraReducers: builder => {
    builder.addCase(
      setModalComponent,
      <TComponentName extends keyof ModalsPathMap>(
        state: ModalState<TComponentName>,
        { payload }: PayloadAction<SetModalParams<TComponentName>>
      ): ModalState<keyof ModalsPathMap> => {
        const { modalComponent, eventTargetId, ignoreClickOutside = false, eventOrigin } = payload
        return {
          ...state,
          open: true,
          eventTargetId,
          modalComponent,
          ignoreClickOutside,
          eventOrigin,
        }
      }
    )
  },
})

export default modalSlice.reducer

// No-argument actions need to be re-exported because redux-toolkit is wrong
export function resetModal() {
  return modalSlice.actions.resetModal(undefined)
}
