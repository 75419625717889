import { SanitizeConfig } from 'components/atoms/SanitizedHtml/SanitizedHtml.types'

// defaultSanitizeConfig combines package defaults with our own requirements
// https://github.com/cure53/DOMPurify/wiki/Default-TAGs-ATTRIBUTEs-allow-list-&-blocklist

export const defaultSanitizeConfig = {
  ALLOWED_TAGS: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'p',
    'ul',
    'ol',
    'nl',
    'li',
    'b',
    'i',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'span',
    'table',
    'thead',
    'caption',
    'tbody',
    'tr',
    'th',
    'td',
    'pre',
    'u',
    'sup',
    'tagged',
    's',
  ],
  KEEP_CONTENT: true,
  ALLOWED_ATTR: ['class', 'href', 'name', 'target', 'type', 'start', 'colspan', 'rowspan', 'style', 'rel'],
  ALLOW_DATA_ATTR: true,
  // URL schemes we permit
  ALLOWED_URI_REGEXP: /^(?:(?:http|https|ftp|mailto):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
} as const satisfies SanitizeConfig

export default {}
