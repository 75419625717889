// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols

/* eslint-disable */
import { UserSwitcherAccount, UserSwitcherUser } from 'components/organisms/UserSwitcher/UserSwitcher.types'
import { Role } from 'types/User'

const avatarFemale1 = '/images/nakhoda/avatars/female1.jpg'
const avatarFemale2 = '/images/nakhoda/avatars/female2.jpg'
const avatarFemale3 = '/images/nakhoda/avatars/female3.jpg'
const avatarFemale4 = '/images/nakhoda/avatars/female4.jpg'
const avatarFemale5 = '/images/nakhoda/avatars/female5.jpg'
const avatarFemale6 = '/images/nakhoda/avatars/female6.jpg'
const avatarFemale7 = '/images/nakhoda/avatars/female7.jpg'
const avatarFemale8 = '/images/nakhoda/avatars/female8.jpg'
const avatarFemale9 = '/images/nakhoda/avatars/female9.jpg'
const avatarFemale10 = '/images/nakhoda/avatars/female10.jpg'
const avatarFemale11 = '/images/nakhoda/avatars/female11.jpg'
const avatarFemale12 = '/images/nakhoda/avatars/female12.jpg'
const avatarFemale13 = '/images/nakhoda/avatars/female13.jpg'
const avatarFemale14 = '/images/nakhoda/avatars/female14.jpg'
const avatarFemale15 = '/images/nakhoda/avatars/female15.jpg'
const avatarFemale16 = '/images/nakhoda/avatars/female16.jpg'
const avatarFemale17 = '/images/nakhoda/avatars/female17.jpg'
const avatarFemale18 = '/images/nakhoda/avatars/female18.jpg'
const avatarMale1 = '/images/nakhoda/avatars/male1.jpg'
const avatarMale2 = '/images/nakhoda/avatars/male2.jpg'
const avatarMale3 = '/images/nakhoda/avatars/male3.jpg'
const avatarMale4 = '/images/nakhoda/avatars/male4.jpg'
const avatarMale5 = '/images/nakhoda/avatars/male5.jpg'
const avatarMale6 = '/images/nakhoda/avatars/male6.jpg'
const avatarMale7 = '/images/nakhoda/avatars/male7.jpg'
const avatarMale8 = '/images/nakhoda/avatars/male8.jpg'
const avatarMale9 = '/images/nakhoda/avatars/male9.jpg'
const avatarMale10 = '/images/nakhoda/avatars/male10.jpg'
const avatarMale11 = '/images/nakhoda/avatars/male11.jpg'
const avatarMale12 = '/images/nakhoda/avatars/male12.jpg'
const avatarMale13 = '/images/nakhoda/avatars/male13.jpg'
const avatarMale14 = '/images/nakhoda/avatars/male14.jpg'
const avatarMale15 = '/images/nakhoda/avatars/male15.jpg'
const avatarMale16 = '/images/nakhoda/avatars/male16.jpg'
const avatarMale17 = '/images/nakhoda/avatars/male17.jpg'
const avatarMale18 = '/images/nakhoda/avatars/male18.jpg'
/* eslint-enable */

const avatarDefaultFemale = { left: avatarFemale18, right: avatarFemale18 }
const avatarDefaultMale = { left: avatarMale18, right: avatarMale18 }

const account1: UserSwitcherAccount = {
  side: 'left',
  name: 'Global Investment Bank PLC',
  domain: 'zippymail.info',
}

const account2: UserSwitcherAccount = {
  side: 'right',
  name: 'US Financial Holdings Inc.',
  domain: 'devnullmail.com',
}

const account3: UserSwitcherAccount = {
  name: 'UK Investment Fund Limited',
  domain: 'mailinator.com',
}

const account4: UserSwitcherAccount = {
  name: 'Frontend Team Inc',
  domain: 'tradermail.info',
}

const account5: UserSwitcherAccount = {
  name: 'Do not make this an Advisor Inc',
  domain: 'advisor-client.createiq.dev',
}

export const accounts = [account1, account2, account3, account4, account5]

function mk2Users({
  firstName,
  surname,
  emailPrefix,
  avatar,
  role,
}: {
  firstName: string
  surname: string
  emailPrefix: string
  avatar: { left: string; right?: string }
  role?: Role
}): [UserSwitcherUser, UserSwitcherUser] {
  return [
    {
      avatar: avatar.left,
      accountName: account1.name,
      accountAllowedDomain: account1.domain,
      userFirstName: firstName,
      userLastName: `${surname} 1`,
      userEmail: `nkai_${emailPrefix}_1@${account1.domain}`,
      userPassword: 'ISDAPass01!',
      roleType: role || 'super_manager',
    },
    {
      avatar: avatar.right || avatarDefaultMale.right,
      accountName: account2.name,
      accountAllowedDomain: account2.domain,
      userFirstName: firstName,
      userLastName: `${surname} 2`,
      userEmail: `nkai_${emailPrefix}_2@${account2.domain}`,
      userPassword: 'ISDAPass01!',
      roleType: role || 'super_manager',
      otherParty: true,
    },
  ]
}

const enableNakhodaAdminUsers = process.env.NEXT_PUBLIC_USER_SWITCHER_ENABLE_NAKHODA_ADMINS === 'true'

export const users: UserSwitcherUser[] = [
  {
    avatar: avatarMale1,
    accountName: account1.name,
    accountAllowedDomain: account1.domain,
    userFirstName: 'Editor Gary',
    userLastName: 'Blizzard 1',
    userEmail: `nkai_gary_editor@${account1.domain}`,
    userPassword: 'ISDAPass01!',
    roleType: 'editor',
  },
  {
    avatar: avatarMale2,
    accountName: account2.name,
    accountAllowedDomain: account2.domain,
    userFirstName: 'Editor Lord',
    userLastName: 'Farquaad 2',
    userEmail: `nkai_farquaad_editor@${account2.domain}`,
    userPassword: 'ISDAPass01!',
    roleType: 'editor',
    otherParty: true,
  },
  {
    avatar: avatarMale4,
    accountName: account1.name,
    accountAllowedDomain: account1.domain,
    userFirstName: 'Approver Hubert',
    userLastName: 'Blaine 1',
    userEmail: `nkai_hubert_approver@${account1.domain}`,
    userPassword: 'ISDAPass01!',
    roleType: 'approver',
  },
  {
    avatar: avatarMale5,
    accountName: account2.name,
    accountAllowedDomain: account2.domain,
    userFirstName: 'Approver Oliver',
    userLastName: 'Smith 2',
    userEmail: `nkai_oliver_approver@${account2.domain}`,
    userPassword: 'ISDAPass01!',
    roleType: 'approver',
    otherParty: true,
  },
  ...mk2Users({
    firstName: 'Abbas',
    surname: 'Al-Amin',
    emailPrefix: 'abbasalamin',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Henri',
    surname: 'Cook',
    emailPrefix: 'henri',
    avatar: { left: avatarMale10, right: avatarMale14 },
  }),
  ...mk2Users({
    firstName: 'Faith',
    surname: 'Ho',
    emailPrefix: 'faith',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Tiffany',
    surname: 'Low',
    emailPrefix: 'tiffany',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Alin',
    surname: 'Pasalega',
    emailPrefix: 'alin',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Alex',
    surname: 'Trifu',
    emailPrefix: 'alex',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Maria',
    surname: 'Marin',
    emailPrefix: 'maria',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Sandeep',
    surname: 'Pasunuri',
    emailPrefix: 'sandeep',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Sarah',
    surname: 'Dixey',
    emailPrefix: 'sarah',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Britania',
    surname: 'Ebanks',
    emailPrefix: 'britania',
    avatar: { left: avatarFemale1, right: avatarFemale1 },
  }),
  ...mk2Users({
    firstName: 'Karishma',
    surname: 'Kotecha',
    emailPrefix: 'karishma',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Harraj',
    surname: 'Sohal',
    emailPrefix: 'harraj',
    avatar: { left: avatarMale15, right: avatarMale15 },
  }),
  ...mk2Users({
    firstName: 'Dom',
    surname: 'Wright',
    emailPrefix: 'dom',
    avatar: { left: avatarMale16, right: avatarMale16 },
  }),
  ...mk2Users({
    firstName: 'Saravanan',
    surname: 'Periyasamy',
    emailPrefix: 'saravanan',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Madalin',
    surname: 'Gorbanescu',
    emailPrefix: 'madalin',
    avatar: { left: avatarMale17, right: avatarMale17 },
  }),
  ...mk2Users({
    firstName: 'Shilpa',
    surname: 'Bhandarkar',
    emailPrefix: 'shilpa',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Clare',
    surname: 'Godson',
    emailPrefix: 'claregodson',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Charlene',
    surname: 'Ashu',
    emailPrefix: 'charlene',
    avatar: { left: avatarFemale7, right: avatarFemale7 },
  }),
  ...mk2Users({
    firstName: 'Claire',
    surname: 'Mostert',
    emailPrefix: 'clairemostert',
    avatar: { left: avatarFemale2, right: avatarFemale2 },
  }),
  ...mk2Users({
    firstName: 'Jamie',
    surname: 'Johnson',
    emailPrefix: 'jamiejohnson',
    avatar: { left: avatarMale18, right: avatarMale18 },
  }),
  ...mk2Users({
    firstName: 'Ritchie',
    surname: 'Allen',
    emailPrefix: 'ritchieallen',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Hannah',
    surname: 'Modarres',
    emailPrefix: 'hannahmodarres',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Myfanwy',
    surname: 'Lock',
    emailPrefix: 'myfanwylock',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Mathew',
    surname: 'Mannion',
    emailPrefix: 'mathewmannion',
    avatar: { left: avatarMale12, right: avatarMale12 },
  }),
  ...mk2Users({
    firstName: 'Nick',
    surname: 'Kaijaks',
    emailPrefix: 'nick',
    avatar: { left: avatarMale3, right: avatarMale3 },
  }),
  ...mk2Users({
    firstName: 'Leonor',
    surname: 'Aidos',
    emailPrefix: 'leo',
    avatar: { left: avatarFemale3, right: avatarFemale3 },
  }),
  ...mk2Users({
    firstName: 'Nikos',
    surname: 'Argalias',
    emailPrefix: 'manager_nikos',
    avatar: { left: avatarMale3, right: avatarMale3 },
  }),
  ...mk2Users({
    firstName: 'Banurekha',
    surname: 'Mohan',
    emailPrefix: 'manager_bmohan',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Ilinca',
    surname: 'Bera',
    emailPrefix: 'manager_ibera',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Genesis',
    surname: 'Nongo',
    emailPrefix: 'manager_gnongo',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Samir',
    surname: 'Garg',
    emailPrefix: 'samir',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Marian',
    surname: 'Haralamb',
    emailPrefix: 'marian',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Stefania',
    surname: 'Sopca',
    emailPrefix: 'stefania',
    avatar: { left: avatarFemale2, right: avatarFemale2 },
  }),
  ...mk2Users({
    firstName: 'Generic',
    surname: 'User',
    emailPrefix: 'generic',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Admin',
    surname: 'Alice',
    emailPrefix: 'adm_alice',
    avatar: avatarDefaultFemale,
  }),
  ...mk2Users({
    firstName: 'Admin',
    surname: 'Barry',
    emailPrefix: 'adm_barry',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Manager',
    surname: 'Charlie',
    emailPrefix: 'manager_charlie',
    avatar: avatarDefaultMale,
  }),
  ...mk2Users({
    firstName: 'Manager',
    surname: 'Dave',
    emailPrefix: 'manager_dave',
    avatar: avatarDefaultMale,
  }),
  {
    avatar: avatarFemale4,
    accountName: '(SSO) US Asset Manager Ltd.',
    accountAllowedDomain: 'tradermail.info',
    userFirstName: 'SSO Jane',
    userLastName: 'Smith',
    userEmail: 'nkai_me@tradermail.info',
    userPassword: 'ISDAPass01!',
    roleType: 'manager',
  },
  {
    avatar: avatarMale6,
    accountName: 'Frontend Team Inc.',
    accountAllowedDomain: 'tradermail.info',
    userFirstName: 'Eddie',
    userLastName: 'Murphy',
    userEmail: 'nkai_eddie@tradermail.info',
    userPassword: 'ISDAPass01!',
    roleType: 'super_manager',
  },
  {
    avatar: avatarFemale5,
    accountName: 'Nakhoda SuperManager',
    accountAllowedDomain: 'nakhoda.ai',
    userFirstName: 'Queen',
    userLastName: 'Boadicea',
    userPassword: 'ISDAPass01!',
    userEmail: 'boadicea@nakhoda.ai',
    roleType: 'nakhoda_super_manager',
    disabled: !enableNakhodaAdminUsers,
  },
  {
    avatar: avatarMale9,
    accountName: 'Nakhoda SuperManager',
    accountAllowedDomain: 'nakhoda.ai',
    userFirstName: 'King',
    userLastName: 'Triton',
    userPassword: 'ISDAPass01!',
    userEmail: 'triton@nakhoda.ai',
    roleType: 'nakhoda_super_manager',
    disabled: !enableNakhodaAdminUsers,
  },
]
