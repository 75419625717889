import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { WorkspaceState } from 'types/redesignStore'

export const initialState: WorkspaceState = {}

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setCurrentWorkspace: (state, { payload: { workspaceId } }: PayloadAction<{ workspaceId: string }>) => ({
      ...state,
      currentWorkspaceId: workspaceId,
    }),
  },
})

export const { setCurrentWorkspace } = workspaceSlice.actions
export default workspaceSlice.reducer
